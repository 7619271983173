/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/assets/style/main.css"

import React from "react"
import {
    GoogleReCaptchaProvider
  } from 'react-google-recaptcha-v3';

export const wrapRootElement = ({ element }) => (
    <GoogleReCaptchaProvider reCaptchaKey="6Le0jvwcAAAAAKJ0DpPHSvKBi9nj-QlOQjGKstpK">
        {element}
    </GoogleReCaptchaProvider>
)
